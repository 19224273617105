import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const conditions = () => (
  <Layout>
    <SEO title="Conditions" />
    <div class="px-4 mt-2 mb-5">
      <h1 class="display-5 mb-4 fw-bold text-center">
        Conditions And Terms Of Use
      </h1>
      <div class="col-lg-6 mb-4 mx-auto lead">
        <ol>
          <li class="mb-4">
            Access to and use of www.igmobility.co.uk (“Website”) is provided by
            Inspire Global Mobility Consulting LTD on the terms and conditions
            set out below. By accessing and using the Website, you will be bound
            by these terms. Please note that these terms are subject to change
            from time to time, so should be checked regularly. Every time you
            wish to use our Website, please check these terms to ensure you
            understand the terms that apply at that time. Continued use of this
            Website will be deemed acceptance of the updated or amended terms
            and conditions. If you do not agree to these terms, then you should
            not use our Website.
          </li>
          <li class="mb-4">
            <strong>Use of the Website</strong> - You acknowledge that you shall
            use the Website only for lawful purposes, for your own personal or
            internal business use.
          </li>
          <li class="mb-4">
            <strong>Copyright</strong> - All copyright, trademarks, design
            rights, patents and other intellectual property rights on this
            Website are vested in Inspire Global Mobility Consulting LTD. All
            copyright notices in any original materials must be retained.
            <br></br>
            <br></br>This Website may not be modified, disassembled, decompiled
            or reverse engineered in any way. No part of the Website may be
            reproduced without our express permission.
          </li>
          <li class="mb-4">
            <strong>Availability</strong> - While we strive to ensure that the
            availability of the Website remains high and maintain the continuity
            of the Website, the internet is not always a stable environment. We
            do not guarantee that our site, or any content on it, will always be
            available or be uninterrupted. We shall not be liable if, for
            whatever reason, the Website is unavailable at any time. Access to
            the Website may be suspended temporarily and without notice in the
            case of system failure, maintenance, or repair or for reasons beyond
            our control. We shall not be held liable for any failure to provide
            or delay in us providing a service through this Website resulting
            from any occurrence beyond our reasonable control including (without
            limitation) strikes, breakdown of systems or network access, fire,
            explosion or accident failure of any third-party telecommunications
            or service provider.
            <br></br>
            <br></br>
            You are also responsible for ensuring that all persons who access
            our site through your internet connection are aware of these terms
            of use and other applicable terms and conditions, and that they
            comply with them.
          </li>
          <li class="mb-4">
            <strong>Permitted use</strong> - We hereby grant you permission to
            access and use our website, subject to the following conditions:
            <br></br>
            <br></br>
            <ol>
              <li>
                Not to distribute any part or parts of the Website, including
                but not limited to any website content, in any medium without
                our prior authorisation.
              </li>
              <li>Not to alter or modify any part of the Website.</li>
              <li>
                Not to disable or interfere with any security related features
                of the Website.
              </li>
              <li>
                Not to use Website in any way that is or may be impacting user
                access to this Website.
              </li>
              <li>
                Not use, the Website for any purpose that is unlawful,
                defamatory, harassing, abusive, fraudulent, or obscene way or in
                any other inappropriate.
              </li>
            </ol>
            <br></br>
            Any breach of the above-mentioned conditions shall constitute a
            breach of the Website terms and conditions.
          </li>
          <li class="mb-4">
            <strong>Breach of Website terms &amp; conditions</strong> - If we
            consider that you have breached any of these Website acceptable use
            terms, we may take such action that we consider appropriate
            including, but not to be limited to:
            <br></br>
            <br></br>
            <ol>
              <li>Issue a warning to you.</li>
              <li>
                Legal proceedings against you for reimbursement of all costs on
                an indemnity basis resulting from the breach.
              </li>
              <li>
                If you are a registered user immediate, temporary, or permanent
                withdrawal of your right to use our site.
              </li>
              <li>
                Disclosure of such information to law enforcement authorities as
                we reasonably feel is necessary.
              </li>
            </ol>
            <br></br>
            We exclude liability for actions taken in response to breaches of
            these Website acceptable use terms. The responses described above
            are not limited, and we may take any other action we reasonably deem
            appropriate.
          </li>
          <li class="mb-4">
            <strong>Disclaimers</strong> - Your access to and use of the Website
            is at your own risk. The Website is provided on an ‘as-is’ basis.
            Inspire Global Mobility Consulting LTD LTD makes no warranties,
            representations, or undertakings about any of the content of this
            Website. Where appropriate, your statutory rights are not affected
            by these terms. In particular, Inspire Global Mobility Consulting
            LTD LTD takes all necessary measures to protect our users from any
            malware or bugs or other items of a destructive nature however
            Inspire Global Mobility Consulting LTD LTD cannot warrant that this
            Website is free of malware or bugs and other items of a destructive
            nature. You are responsible for implementing sufficient procedures
            and virus checks (including anti-virus and other security checks) to
            satisfy requirements for the accuracy and security of the data input
            and output. You should use your own virus protection software.
            <br></br>
            <br></br>
            You must not misuse our site by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our site, the server on which our site is stored, or any
            server, computer or database connected to our site. You must not
            attack our site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offence under the Computer Misuse Act 1990. We
            will report any such breach to the relevant law enforcement
            authorities, and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our site will cease immediately.
            <br></br>
            <br></br>
            Inspire Global Mobility Consulting LTD LTD shall not be liable for
            damages, losses (whether direct, indirect, or consequential),
            expenses, liabilities, loss of profits or costs resulting from the
            use of, access to, or reliance upon the information given by its
            employees, agents or subcontractors in relation to, contained in or
            available through, its Website.
          </li>
          <li class="mb-4">
            <strong>Links to and from other websites</strong> - In all cases
            where our website contains links to other websites and resources
            provided by third parties, these links are provided for your
            information only and do not represent an endorsement. We have no
            control over the contents of those websites or resources and, as
            such, make no warranties or assertions as to their content, nor does
            Inspire Global Mobility Consulting LTD LTD have any liability in
            connection with any of them (including, but not limited to,
            liability arising out of any allegation that the content of any
            third-party site infringes any law or the rights of any person or is
            obscene, defamatory, or scandalous).
          </li>
          <li class="mb-4">
            <strong>Personal data</strong> - Inspire Global Mobility Consulting
            LTD LTD will be processing your personal data according to the
            General Data Protection Regulation (GDPR) and additional data
            protection laws. To find out more about how we use your personal
            data you can access our website privacy policy.
          </li>
          <li class="mb-4">
            <strong>Law and jurisdiction</strong> - This agreement and any
            dispute arising out of it will be governed by and in accordance with
            the laws of England and Wales. Any dispute or claim arising out of
            or in connection with this agreement or its formation (including
            non-contractual disputes or claims) shall be subject to the
            exclusive jurisdiction of the courts of England and Wales.
          </li>
        </ol>
      </div>
    </div>
  </Layout>
)

export default conditions
